import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-consumer-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { checkWindow } from "@vfit/shared/data-access";
import { handleEnv } from "@vfit/shared/providers";
var getDataFromDAM = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(fileName) {
        var cmsUrl, baseUrl, url, dataFromCmsRes, dataFromCms;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    cmsUrl = handleEnv("NX_CMS");
                    if (!cmsUrl) {
                        _ctx.next = 20;
                        break;
                    }
                    baseUrl = new URL(cmsUrl).origin;
                    baseUrl = "".concat(baseUrl, "/content/dam/webaem/vodafone");
                    url = "".concat(baseUrl, "/").concat(fileName);
                    if (!url) {
                        _ctx.next = 19;
                        break;
                    }
                    _ctx.prev = 6;
                    _ctx.next = 9;
                    return fetch(url);
                case 9:
                    dataFromCmsRes = _ctx.sent;
                    _ctx.next = 12;
                    return dataFromCmsRes.json();
                case 12:
                    dataFromCms = _ctx.sent;
                    return _ctx.abrupt("return", dataFromCms);
                case 16:
                    _ctx.prev = 16;
                    _ctx.t0 = _ctx["catch"](6);
                    return _ctx.abrupt("return", false);
                case 19:
                    return _ctx.abrupt("return", false);
                case 20:
                    return _ctx.abrupt("return", false);
                case 21:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                6,
                16
            ]
        ]);
    }));
    return function getDataFromDAM(fileName) {
        return _ref.apply(this, arguments);
    };
}();
var appendHiddenQueryParams = function(data, bodyServiceInputKey) {
    if (!checkWindow()) return data;
    var params = new URLSearchParams(window.location.search);
    var paramsObj = Array.from(params.keys()).reduce(function(acc, val) {
        return _object_spread_props(_object_spread({}, acc), _define_property({}, val, params.get(val)));
    }, {});
    if (paramsObj === null || paramsObj === void 0 ? void 0 : paramsObj["app"]) delete paramsObj["app"];
    if (bodyServiceInputKey) {
        return _object_spread_props(_object_spread({}, data), _define_property({}, bodyServiceInputKey, _object_spread({}, data === null || data === void 0 ? void 0 : data[bodyServiceInputKey], paramsObj)));
    }
    return _object_spread({}, paramsObj, data);
};
var createSubmitMethod = function(data, inputs, submitConfig) {
    var method = submitConfig.method, urlConfig = submitConfig.url, headers = submitConfig.headers, splitHiddenValues = submitConfig.splitHiddenValues, bodyServiceInputKey = submitConfig.bodyServiceInputKey, enableQueryParamsInHidden = submitConfig.enableQueryParamsInHidden;
    var url = method === "GET" ? "".concat(urlConfig).concat(new URLSearchParams(data)) : urlConfig;
    var apiHeaders = {};
    var isMultipartFormData = false;
    headers === null || headers === void 0 ? void 0 : headers.forEach(function(headerData) {
        if (headerData.title === "content-type" && headerData.value === "multipart/form-data") {
            isMultipartFormData = true;
        }
        apiHeaders[headerData.title] = headerData.value;
    });
    var formData;
    var body;
    if (isMultipartFormData) {
        var ref;
        formData = new FormData();
        (ref = Object.keys(data)) === null || ref === void 0 ? void 0 : ref.forEach(function(keyData) {
            formData.append(keyData, data[keyData]);
        });
        body = formData;
    } else {
        var dataToSend = data;
        if (splitHiddenValues && bodyServiceInputKey) {
            var dataToFormat = _define_property({}, bodyServiceInputKey, {});
            Object.keys(data).forEach(function(dataKey) {
                var foundedInput = inputs.find(function(el) {
                    return el.name === dataKey;
                });
                var value = data[dataKey];
                if (value) {
                    if (foundedInput === null || foundedInput === void 0 ? void 0 : foundedInput.isHidden) {
                        dataToFormat[dataKey] = value;
                    } else {
                        dataToFormat[bodyServiceInputKey][dataKey] = value;
                    }
                }
            });
            dataToSend = dataToFormat;
        }
        body = JSON.stringify(enableQueryParamsInHidden ? appendHiddenQueryParams(dataToSend, bodyServiceInputKey) : dataToSend);
    }
    return {
        url: url,
        method: method,
        body: body,
        headers: apiHeaders
    };
};
export { getDataFromDAM, appendHiddenQueryParams, createSubmitMethod };
