import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { computeDatalayer, trackOptions } from "./datalayer";
import { shouldTrack, retrieveBasePageName } from "./utils";
import { LoggerInstance } from "../logger";
var isDevLocal = "production" === "development";
var track = function(datalayer, trackingType) {
    var ref, ref1, ref2, ref3;
    var normalizedDatalayer = _object_spread({}, datalayer, (datalayer === null || datalayer === void 0 ? void 0 : datalayer.product_name) && {
        page_name: datalayer === null || datalayer === void 0 ? void 0 : (ref = datalayer.page_name) === null || ref === void 0 ? void 0 : ref.replace(new RegExp("".concat(datalayer.product_name, ":"), "i"), ""),
        link_name: datalayer === null || datalayer === void 0 ? void 0 : (ref1 = datalayer.link_name) === null || ref1 === void 0 ? void 0 : ref1.replace(new RegExp("".concat(datalayer.product_name, ":"), "ig"), "")
    });
    if (trackingType !== "link") {
        window.utag_data = normalizedDatalayer;
    }
    if (isDevLocal) {
        LoggerInstance.debug("Tracking datalayer --> ", normalizedDatalayer);
    } else if (trackingType === "link" && ((ref2 = window.utag) === null || ref2 === void 0 ? void 0 : ref2.link)) {
        var ref4;
        (ref4 = window.utag) === null || ref4 === void 0 ? void 0 : ref4.link(normalizedDatalayer);
    } else if ((ref3 = window.utag) === null || ref3 === void 0 ? void 0 : ref3.view) {
        window.utag.view(normalizedDatalayer);
    } else {
        document.addEventListener("utag_ready", function() {
            var ref;
            if ((ref = window.utag) === null || ref === void 0 ? void 0 : ref.view) window.utag.view(normalizedDatalayer);
        }, {
            once: true
        });
    }
    window.utag_last_tracked_data = normalizedDatalayer;
};
var useTracking = function(param) {
    var event = param.event, event_label = param.event_label, opts = param.opts, pageProduct = param.pageProduct, cartProduct = param.cartProduct, visitorTrackingOpts = param.visitorTrackingOpts, disableInitialTrack = param.disableInitialTrack;
    var ref = useState(false), tracked = ref[0], setTracked = ref[1];
    var ref1 = useState(null), datalayer = ref1[0], setDatalayer = ref1[1];
    var ref2 = useState(false), isReadyTrackView = ref2[0], setIsReadyTrackView = ref2[1];
    var trackViewRef = useRef();
    var trackView = function(param) {
        var channel = param.channel, desc = param.desc, event_name = param.event_name, event_label_track = param.event_label_track, event_category = param.event_category, event_action = param.event_action, page_error = param.page_error, page_error_code = param.page_error_code, page_type = param.page_type, page_name_override = param.page_name_override, ignore_base_page_name = param.ignore_base_page_name, form_error = param.form_error, tracking_type = param.tracking_type, checkout_consents = param.checkout_consents, journey_name = param.journey_name, journey_type = param.journey_type, page_section = param.page_section, page_subsection = param.page_subsection, page_browser_system = param.page_browser_system, page_environment = param.page_environment, product_id = param.product_id, product_name = param.product_name, product_category = param.product_category, product_price = param.product_price, product_quantity = param.product_quantity, visitor_type = param.visitor_type, versatile_prop = param.versatile_prop, transaction_id = param.transaction_id;
        var basePageProductName = "";
        if (pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_name) {
            if (Array.isArray(pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_name)) {
                var ref;
                basePageProductName = (pageProduct === null || pageProduct === void 0 ? void 0 : (ref = pageProduct.product_name) === null || ref === void 0 ? void 0 : ref[0]) || "";
            } else {
                basePageProductName = (pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_name) || "";
            }
        }
        if (!basePageProductName && product_name) {
            if (Array.isArray(product_name)) {
                basePageProductName = (product_name === null || product_name === void 0 ? void 0 : product_name[0]) || "";
            } else {
                basePageProductName = product_name || "";
            }
        }
        var basePageName = !ignore_base_page_name ? "".concat(retrieveBasePageName(cartProduct, basePageProductName), ":") : "";
        var pageName = "".concat(basePageName).concat(tracking_type === "link" ? page_name_override || event_label || (event === null || event === void 0 ? void 0 : event[0]) : page_name_override || event_label_track || event_name);
        var linkName = tracking_type === "link" && "".concat(basePageName).concat(event_label_track || event_name);
        var datalayerTrackView = _object_spread(_object_spread_props(_object_spread({}, datalayer), {
            page_name: pageName
        }), event_name && {
            event_name: [
                event_name
            ]
        }, linkName && {
            link_name: linkName
        }, event_label_track && {
            event_label: event_label_track
        }, event_category && {
            event_category: event_category
        }, event_action && {
            event_action: event_action
        }, page_error && {
            page_error: page_error
        }, page_error_code && {
            page_error_code: page_error_code
        }, page_type && {
            page_type: page_type
        }, form_error && {
            form_error: form_error
        }, checkout_consents && {
            checkout_consents: checkout_consents
        }, journey_name && {
            journey_name: journey_name
        }, journey_type && {
            journey_type: journey_type
        }, page_section && {
            page_section: page_section
        }, page_subsection && {
            page_subsection: page_subsection
        }, page_browser_system && {
            page_browser_system: page_browser_system
        }, product_id && {
            product_id: product_id
        }, product_name && {
            product_name: product_name
        }, product_category && {
            product_category: product_category
        }, product_price && {
            product_price: product_price
        }, product_quantity && {
            product_quantity: product_quantity
        }, page_environment && {
            page_environment: page_environment
        }, visitor_type && {
            visitor_type: visitor_type
        }, versatile_prop !== undefined && {
            versatile_prop: versatile_prop
        }, channel && {
            channel: channel
        }, desc && {
            desc: desc
        }, transaction_id && {
            transaction_id: transaction_id
        });
        track(datalayerTrackView, tracking_type);
    };
    var trackWithNewDatalayer = useCallback(function(newDatalayer) {
        var tempDatalayer = computeDatalayer(newDatalayer.event, newDatalayer.event_label, newDatalayer.opts, newDatalayer.pageProduct, newDatalayer.cartProduct, newDatalayer.visitorTrackingOpts);
        track(tempDatalayer);
        if (!newDatalayer.notOverrideDL) setDatalayer(tempDatalayer);
    }, []);
    useEffect(function() {
        if (!tracked && datalayer && !disableInitialTrack && shouldTrack(event === null || event === void 0 ? void 0 : event[0])) {
            track(datalayer);
            setTracked(true);
        }
        if (datalayer) {
            trackViewRef.current = trackView;
            setIsReadyTrackView(true);
        }
    }, [
        datalayer
    ]);
    useEffect(function() {
        setDatalayer(computeDatalayer(event, event_label, opts, pageProduct, cartProduct, visitorTrackingOpts));
    }, []);
    return {
        trackView: function(track_view) {
            var ref;
            return trackViewRef === null || trackViewRef === void 0 ? void 0 : (ref = trackViewRef.current) === null || ref === void 0 ? void 0 : ref.call(trackViewRef, track_view);
        },
        trackWithNewDatalayer: trackWithNewDatalayer,
        isReadyTrackView: isReadyTrackView
    };
};
export default useTracking;
export var tracking = function(datalayer, trackingType) {
    var ref;
    var trackingData = _object_spread({}, trackOptions(datalayer.event_name, datalayer.event_label, undefined, Array.isArray(datalayer.product_name) ? ((ref = datalayer.product_name) === null || ref === void 0 ? void 0 : ref[0]) || "" : datalayer.product_name || ""), datalayer);
    track(trackingData, trackingType);
    return trackingData;
};
